<template>
    <div>
      <list-layout
        ref="listLayout"
        :filter-form="filterForm"
       
        :thead="thead"
        :on-fetch="getList"
        @command="handleOperation"
      >
       <template #filter>
        <recordFilter  :export-data="{}" :filter-change="onFilter" />
     </template>
     <template #item_nickname="{row}">{{row.user.nickname || '--'}}</template>
     <template #item_title_name="{row}">{{row.user.title_name || '--'}}</template>
     <template #item_status_text="{row}">
      <ListStatusText
                :text="row.status_text"
                :type="
                  row.status_text === '进行中'
                    ? 'success'
                    : row.status_text === '未开始'
                    ? 'warning'
                    : row.status_text === '已结束'
                    ? 'info'
                    : 'danger'
                "
              ></ListStatusText>
  
    </template>
      </list-layout>
    </div>
  </template>
  
  <script>
  import ListLayout from '@/base/layout/ListLayout'
  
  import {
    getReserveRecords,
  } from '@/modules/venue/api'
  import recordFilter from'@/modules/venue/components/venue-detail/recordFilter'
  import ListStatusText from "@/base/components/List/ListStatusText";
  export default {
    components: { ListLayout, ListStatusText,recordFilter },
    data() {
      return {
        filterForm: {
          page:1,
          page_size:15
        },
        
        thead: [
          // {
          //   type: 'selection',
          //   fixed: 'left',
          // },
          // {prop: 'code', label: '审批编号', width: 140},
          {prop: 'nickname', label: '申请人',  minWidth: 170,type: 'slot'},
          {prop: 'title_name', label: '申请人职务',  minWidth: 170, type: 'slot'},
        
          {prop: 'purpose', label: '场地标题',  minWidth: 170,},
          {prop: 'type_text', label: '场地类型',  minWidth: 80, },
  
       

    
          {prop: 'reserve_times_text', label: '使用时间',  minWidth: 240},
          {prop: 'status_text', label: '审批状态',  minWidth: 100,type: 'slot'},
       
          { type: 'operation', operations: [
          {command: 'view', text: '查看',},
             
        
              
            ], 
            label: '操作',  minWidth: 60
          },
        ],
      }
    },
    methods: {
      getList(q = {}) {
        return getReserveRecords({id:this.$route.params.id, ...this.filterForm, ...q })
      },
      // 刷新列表
      refreshList() {
        this.$refs.listLayout.getList()
      },
      handleOperation(e) {
        switch (e.command) {
          case 'view': {
            this.$router.push({
              name: 'VenueCheckView',
              params: {
                id: e.row.id,
              },
            })
            break
          }
          default:
        }
      },
      // 筛选回调
      onFilter(filterObj) {
        Object.assign(this.filterForm, filterObj, { page: 1 })
        this.$refs.listLayout.getList()
      },
    },
  }
  </script>
  
  <style lang="scss" scoped></style>
  