import { render, staticRenderFns } from "./venue-detail-record.vue?vue&type=template&id=874534dc&scoped=true&"
import script from "./venue-detail-record.vue?vue&type=script&lang=js&"
export * from "./venue-detail-record.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "874534dc",
  null
  
)

export default component.exports