var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"on-fetch":_vm.getList},on:{"command":_vm.handleOperation},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('recordFilter',{attrs:{"export-data":{},"filter-change":_vm.onFilter}})]},proxy:true},{key:"item_nickname",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.nickname || '--'))]}},{key:"item_title_name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.title_name || '--'))]}},{key:"item_status_text",fn:function(ref){
var row = ref.row;
return [_c('ListStatusText',{attrs:{"text":row.status_text,"type":row.status_text === '进行中'
                ? 'success'
                : row.status_text === '未开始'
                ? 'warning'
                : row.status_text === '已结束'
                ? 'info'
                : 'danger'}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }